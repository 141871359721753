
import { ref, onMounted, nextTick } from "vue";
import { onUnmounted } from "@vue/runtime-core";
import Footer from "@/components/home/Footer.vue";
// import Nav from "@/components/home/Nav.vue";

export default {
  components: { Footer },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    // 定义矩形元素的初始状态
    const items = ref([
      {
        width: "60%",
        marginLeft: "0px",
        clicked: true,
        description:
          "上海总部：上海市黄浦区天津路 180 号应氏大厦 15 楼 A+B 单元",
      },
      {
        width: "calc(40% / 4 - 5px)",
        marginLeft: "5px",
        clicked: false,
        description: "深圳分公司：深圳市福田区福华路 319 号兆邦基金融大厦 2405",
      },
      {
        width: "calc(40% / 4 - 5px)",
        marginLeft: "5px",
        clicked: false,
        description:
          "广州分公司：广州市天河区体育东路116号1302单元",
      },
      {
        width: "calc(40% / 4 - 5px)",
        marginLeft: "5px",
        clicked: false,
        description: "长沙办事处：长沙市雨花区劳动中路 2 号友阿百利大厦 1522",
      },
      {
        width: "calc(40% / 4 - 5px)",
        marginLeft: "5px",
        clicked: false,
        description: "武汉办事处：武汉市东西湖区宏图一路9号梦想之城T3栋",
      },
    ]);

    // 点击矩形元素时切换状态
    const toggleClick = (index: number) => {
      items.value.forEach((item, i) => {
        if (i === index) {
          item.clicked = true;
          item.width = "60%";
          item.marginLeft = "5px";
        } else {
          item.clicked = false;
          item.width = "calc(40% / 4 - 5px)";
          item.marginLeft = "5px";
        }
      });
    };

    // 在组件挂载时，调整矩形元素的宽度和间距，使其适应容器的宽度
    const adjustItems = () => {
      const container = document.querySelector(".container");
      if (!container) return;
      const containerWidth = container.clientWidth;
      const itemWidth = containerWidth / 2;
      const itemMargin = (containerWidth - itemWidth * 5) / 4;
      items.value.forEach((item, index) => {
        item.width =
          index === 0 ? `${itemWidth * 1.6}px` : `${itemWidth / 4}px`;
        item.marginLeft = index === 0 ? "0px" : `${itemMargin}px`;
      });
    };

    // 在组件挂载后和窗口大小变化时，调整矩形元素的宽度和间距，使其适应容器的宽度
    onMounted(() => {
      nextTick(() => {
        adjustItems();
      });
      window.addEventListener("resize", adjustItems);
    });

    // 在组件卸载时，移除窗口大小变化事件的监听器
    onUnmounted(() => {
      window.removeEventListener("resize", adjustItems);
    });

    return { items, toggleClick };
  },
};
